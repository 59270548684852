import axios from 'axios';
import apiConfig from '../api.config';

export default function updatePoliceSFTP({ hotelId, ftpConfig, fileFormat, filePrefix, fileSufix, ftpDirPath }) {
  const {
    host,
    URIs: { policeReport, policeReportSFTP },
  } = apiConfig;

  const uri = `${host}${policeReport}/${policeReportSFTP.replace(':hotelId', hotelId)}`;

  return axios
    .put(uri, JSON.stringify({ ftpConfig, fileFormat, ftpDirPath, filePrefix, fileSufix }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
