export default `<p align="center"><img src="[chain_logo]" width="auto" height="100"></p>

<p style="font-family: &quot;Times New Roman&quot;, serif; margin: 0cm; text-align: center;"><span style="font-family: Calibri, sans-serif;"><span style="font-size: 21px;"><strong>SEZIONE DI INGRESSO</strong></span><span style="font-size: 16px;">&nbsp;</span></span></p>
&nbsp;
<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">DATI DELL'AZIENDA LOCATRICE&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 95%; border-collapse: collapse; margin-left: 2.32975%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Parte n./Cardex n.:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[police_report_number]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Nome o ragione sociale del titolare:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_name]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">CIF/VAT/NIF:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Comune:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city]&nbsp;</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Provincia:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_province]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Telefono fisso e/o cellulare:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Indirizzo e-mail:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Sito web dell'azienda:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_web]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">URL per identificare l'annuncio:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_ad_url]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">DATI DELL'ALLOGGIO&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.8208%; border-collapse: collapse; margin-left: 2.50896%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Tipo di struttura:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[establishment_type]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Denominazione:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Indirizzo completo:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Codice postale:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Località e provincia:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city], [CIF_province]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">DATI DEL VIAGGIATORE&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.4624%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Nome:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_first_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Cognome:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Secondo cognome:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_second_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Sesso:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_gender]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Numero del documento:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Numero di supporto del documento:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_support_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Tipo di documento:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_type]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Data di emissione:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expedition_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Data di scadenza:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expiration_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Paese del documento:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Nazionalità:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Data di nascita:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_birth_date]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Luogo di residenza abituale:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Indirizzo:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_address]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Codice postale:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Città:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_city]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Provincia:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_province]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Paese:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_country]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Telefono fisso:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_landline_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Cellulare:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">E-mail:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Numero di viaggiatori:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[num_guests]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Grado di parentela:</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_family_relationship]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

<div style="page-break-before:always;margin-top:5cm"></div>&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">DATI DELLA TRANSAZIONE&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.1239%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt; margin-bottom: 40px;">
<tbody>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Dati del contratto:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Numero di riferimento:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[reference_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Data:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Dati di esecuzione del contratto:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Data e ora di ingresso:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date],&nbsp;[entrance_time]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Data e ora di uscita:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[departure_date],&nbsp;[departure_time]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Dati dell'immobile:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Indirizzo completo:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Numero di stanze:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_room_count]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Connessione a internet:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_has_internet]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Dati di pagamento:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Tipo:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_payment_type]</span></p>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p>[legal_cardex]<br><br></p>

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Firma</span></strong></p>
<p><img src="[guest_signature]" width="auto" height="200"></p>
<p></p>`;