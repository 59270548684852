import getActiveDashboardAlerts from "./api/getActiveDashboardAlerts.service";

var host,
  validationsHost,
  bookingOauth,
  policeReportHost,
  billingHost,
  guestlinkHost,
  integrationHubHost,
  policeReportIntegrationHost,
  validationsHost;
switch (process.env.REACT_APP_ENV) {
  case 'development':
    host = 'http://localhost:8001/';
    validationsHost = 'http://localhost:4000/';
    bookingOauth = 'http://localhost:3002/onboarding/auth';
    policeReportHost = 'http://localhost:3099/';
    billingHost = 'http://localhost:3003/';
    guestlinkHost = 'https://guestlink-pre.civitfun.com/';
    integrationHubHost = 'https://api.pre.civitfun.com/';
    policeReportIntegrationHost = 'http://localhost:3099/';
    break;
  case 'preproduction':
    host = 'https://dashboard-api.pre.civitfun.com/';
    validationsHost = 'https://validation-onboarding-api.pre.civitfun.com/';
    bookingOauth = 'https://bookingdotcom.pre.civitfun.com/onboarding/auth';
    policeReportHost = 'https://police-report-pre.civitfun.com/';
    billingHost = 'https://billing-api.pre.civitfun.com/';
    guestlinkHost = 'https://guestlink-pre.civitfun.com/';
    integrationHubHost = 'https://api.pre.civitfun.com/';
    policeReportIntegrationHost = 'https://police-report-integrations.pre.civitfun.com/';
    break;
  case 'production':
  default:
    host = 'https://dashboard-api.civitfun.com/';
    validationsHost = 'https://validation-onboarding-api.civitfun.com/';
    bookingOauth = 'https://bookingdotcom.civitfun.com/onboarding/auth';
    policeReportHost = 'https://police-report-svc.civitfun.com/';
    billingHost = 'https://billing-api.civitfun.com/';
    guestlinkHost = 'http://guestlink.civitfun.com/';
    integrationHubHost = 'https://api.civitfun.com/';
    policeReportIntegrationHost = 'https://police-report-integrations.civitfun.com/';
    break;
}
export default {
  host,
  validationsHost,
  policeReportHost,
  policeReportIntegrationHost,
  billingHost,
  guestlinkHost,
  integrationHubHost,
  URIs: {
    auth: {
      login: 'auth/login',
      register: 'auth/register',
      registerPaperless: 'auth/register-paperless',
      validateUser: 'auth/validate-user',
      superUser: {
        chains: 'auth/superuser/:userId/chains',
        impersonateLogin: 'auth/superuser/:userId/impersonate-login',
      },
      recoverPassword: 'auth/recover-pass',
      changePassword: 'auth/change-pass',
      loggedChangePassword: 'auth/logged-change-pass',
      activateOtaAccount: 'auth/activate-ota-account',
      paymentMethodConfigured: 'auth/payment-method-configured',
    },
    checkOut: {
      getCheckOuts: 'checkouts',
      getSubscriptionStatus: 'checkouts/:hotelId/subscription-status',
    },
    guestlink: {
      getTemplatesAndSubjects: 'guestlink/:hotelId/default-templates',
    },
    doorOpening:{
      setDoorOpeningOauth: 'doors/config-oauth',
    },
    oauth:{
      getOauth: 'get-oauth'
    },
    validations: {
      checkin: 'val/:hotelId/checkin/:action',
      PDFGeneration: 'val/:hotelId/PDFGeneration',
      connection: 'val/:hotelId/connection',
      generateEmail: 'val/:hotelId/check/mail',
      guest: 'val/:hotelId/guest/:action',
      paperless: 'val/:hotelId/paperless/:action',
      payments: 'val/:hotelId/payments/:action',
      onboarding: 'val/:hotelId/onboarding/:action',
      searches: 'val/:hotelId/searches/:action',
    },
    checkins: 'checkins',
    checkinsExport: 'checkins/export',
    bookingsExport: 'bookings/export',
    checkinDetail: 'checkin/:uuid',
    checkinStats: 'checkins/stats',
    checkinStatsExport: 'checkins/stats/export',
    hotels: 'hotels',
    getOneHotel: 'hotel/',
    salesChannels: 'sales-channels',
    updateSalesChannel: 'sales-channels/update/:id',
    createPremiumSuscription: 'stripe/create-premium-suscription',
    iHClientHotel: 'ih-client-hotel',
    appearence: 'hotel/:hotelId/appearance',
    appearenceUpdate: 'hotel/appearance',
    languages: 'languages',
    allHotels: 'all-hotels',
    pmsHotels: 'pms/hotels',
    pmsList: 'pms/list',
    pmsPublicList: 'pms/public-list',
    pmsConfigHotel: 'pms/config-hotel',
    pmsOauth: 'pms/oauth',
    pmsConfig: 'pms/config/:pmsId',
    pmsConfigDynamicForm: 'pms/configDynamicForm/:pmsId',
    pmsConfigUpdate: 'pms/config/:hotelId',
    pmsConnectionCheck: 'pms/connection-check/:hotelId',
    pmsBookingCheck: 'health-check/autocheckin/:hotelId',
    checkinConfig: 'config/checkin/:hotelId',
    updateCheckinConfig: 'config/checkin',
    bookingSearchConfig: 'config/booking-search/:hotelId',
    updateBookingSearch: 'config/booking-search',
    bookings: 'bookings',
    guestForms: 'forms/:hotelId',
    updateForms: 'forms',
    legalTexts: 'legal-texts/:hotelId',
    updateLegalText: 'legal-texts',
    communicationChannels: 'communication-channels/:hotelId',
    communicationEmails: 'communication-emails',
    user: 'user',
    usersList: 'user/list',
    sendUserInvitation: 'user/send-invitation',
    updateAppearenceChain: 'chain/:chainId/appearance',
    medias: 'medias/view',
    createOtaMarketing: 'ota-marketing/create',
    quickguideImages: 'aws/quickguide-images',
    finishQuickguide: 'user/finish-quickguide',
    getPaymentMethods: 'stripe/get-payment-methods/:userId',
    createPaymentMethod: 'stripe/create-payment-method',
    doorsOpeningByHotelList: 'doors/:hotelId/list',
    addDoorOpeningToHotel: 'doors/:hotelId/add',
    updateDoorOpeningToHotel: 'doors/:hotelId/update/:doorId',
    deleteDoorOpeningToHotel: 'doors/:hotelId/delete/:doorId',
    importDoorOpeningToHotel: 'doors/:hotelId/import',
    paymentsList: 'payment/list',
    hotelPaymentConfig: 'payment/config/:platform',
    updateHotelPaymentConfig: 'payment/hotel-platform-config/:hotelId',
    hotelsSelectedPayment: 'payment/hotels-platform',
    doorOpeningDownloadTemplate: 'doors/download-template',
    doorOpeningList: 'doors/enabled-integrations',
    doorOpeningHotels: 'doors/hotel-doors',
    doorOpeningConfig: 'doors/config/:platform',
    updateDoorOpeningConfig: 'doors/hotel-platform-config/:hotelId',
    accountFunctionalities: 'account/functionalities/:hotelId',
    requestFunctionalityContract: 'account/request-functionality-contract/:hotelId',
    sendRequestFuncionalityEmail: 'account/send-request-functionality-email/:hotelId',
    mailTemplates: 'guestlink/:hotelId',
    updateMailTemplateStatus: 'guestlink/:hotelId/update-status',
    mailTemplateDetail: 'guestlink/:hotelId/detail/:guestlinkId',
    getTags: 'tags',
    sendTestEmail: 'guestlink/send-test-email',
    uploadMailAttachments: 'medias/create',
    updateMailTemplate: 'guestlink/:hotelId/update/:guestlinkId',
    guestlinkOptions: 'guestlink/:hotelId/options',
    createGuestlink: 'guestlink/:hotelId/create',
    deleteGuestlink: 'guestlink/:hotelId/delete/:guestlinkId',
    purchasesList: 'purchases',
    purchasesExport: 'purchases/export',
    getEstablishmentTypes: 'establishment-types',
    paperlessPush: 'paperless-push',
    policeReport: 'police-reports',
    policeReportDetail: 'police-reports/detail',
    sendPoliceReport: 'police-reports/:guestId',
    policeReportDownloadTrigger: 'download/trigger',
    downloadPoliceReports: 'ses-file',
    policeReportConfig: 'config/:hotelId',
    policeReportSFTP: 'SFTP-config/:hotelId',
    policeReportSES: 'SES-config/:hotelId',
    upsellSendEmailToHotel: 'booking/upsell',
    upsellSendEmailToGuestApproved: 'booking/upsell',
    upsellSendEmailToGuestRejected: 'booking/upsell',
    sellAddPMSNote: 'checkin/v1/:hotelId/upselling-pms-note',
    upsellAddPMSNote: 'checkin/v1/:hotelId/upselling-pms-note',
    upsellUpdateCharges: 'payment/v1/:hotelId/upsell-update-charges',
    crossSellSendEmail: 'booking/cross-sell',
    crossSellUpdateCharges: 'payment/v1/:hotelId/cross-sell-update-charges',
    guestlinkSmtpConfig: 'guestlink/smtp/config/:hotelId',
    updateGuestlinkSmtConfig: 'guestlink/smtp/config/update/:hotelId',
    checkSmtpConnection: 'guestlink/smtp/check-connection',
    getBillingInformation: 'billing_information/get',
    saveBillingInformation: 'billing_information/save',
    handleSuscription: 'account/handle-suscription/:module',
    paymentSuscriptionStatus: 'payment/suscription-status/:hotelId',
    doorOpeningSuscriptionStatus: 'doors/suscription-status/:hotelId',
    guestlinkSuscriptionStatus: 'guestlink/:hotelId/suscription-status',
    checkinSuscriptionStatus: 'checkins/suscription-status/:hotelId',
    getHotelContractList: 'hotel_contract/list',
    getHotelContractFileURL: 'hotel_contract/get_file/:hotelContractId',
    saveHotelContract: 'hotel_contract/',
    deleteHotelContract: 'hotel_contract/:hotelContractId',
    editHotelContract: 'hotel_contract/:hotelContractId',
    getHotelLicencesList: 'hotel_licence/list',
    getHotelBillingConfiguration: 'configuration',
    deleteHotelLicence: 'hotel_licence/:hotelLicenceId',
    saveBulkHotelLicence: 'hotel_licence/bulk',
    editHotelLicence: 'hotel_licence/:hotelLicenceId',
    getHotelInvoiceFileURL: 'hotel_invoice/get_file/:hotelInvoiceId',
    getHotelInvoicesList: 'hotel_invoice/list',
    deleteHotelInvoice: 'hotel_invoice/:hotelInvoiceId',
    saveHotelInvoice: 'hotel_invoice/',
    editHotelInvoice: 'hotel_invoice/:hotelInvoiceId',
    findHotelInvoiceByInvoiceId: 'hotel_invoice/exists/:invoiceId',
    findHotelInvoiceByInvoiceInternalId: 'hotel_invoice/exists/internal/:invoiceId',
    updateHotelStatusId: 'hotel/change_status/:hotelId',
    policeReportCheckSFTPConnection: 'validations/checkSftp',
    policeReportValidateFileExport: 'validations/fileExport',
    addReservation: 'reservations/v1',
    formFieldsInference: 'formFieldsInference/:hotelId',
    policeReportCheckSESConnection: 'validations/checkSES',
    uploadSliderImage: 'hotel/:hotelId/uploadSliderImage',
    removeSliderImage: 'hotel/:hotelId/removeSliderImage/:uid',
    getActiveDashboardAlerts: 'get-dashboard-alerts',
  },
  bookingOauth,
  cloudbedsOauth: 'https://hotels.cloudbeds.com/api/v1.1/access_token',
};
