import React, { useContext } from 'react';
import styles from './HotelSelector.styles';
import { AutoComplete } from 'antd';
import useHotelSelectorHook from './HotelSelector.hook';
import { capitalize } from '../../../utils/TextUtils';
import { AppContext } from '../../../App.context';
import Colors from 'constants/Colors';

const { Option } = AutoComplete;

export default function HotelSelector({
  onHotelSelected,
  enableAllHotels,
  vertical,
  darkTheme,
  autoSelect,
}) {
  const { appState, appActions } = useContext(AppContext);
  const { state, actions } = useHotelSelectorHook(onHotelSelected, enableAllHotels, autoSelect);

  return (
    <div style={styles.container}>
      <span style={{ ...styles.text, color: darkTheme ? Colors.darkText : Colors.white }}>
        {appActions.translate('hotelSelector.info')}
      </span>
      <div style={vertical ? styles.column : styles.row}>
        <div
          style={{
            ...styles.selector,
          }}
        >
          {state.hotels ? (
            <AutoComplete
              style={{
                ...styles.autocomplete,
                backgroundColor: darkTheme ? Colors.grayBackground : Colors.white,
              }}
              onSearch={actions.handleSearch}
              onChange={actions.onChange}
              onSelect={actions.onHotelSelected}
              placeholder={appActions.translate('hotelSelector.hint')}
              defaultActiveFirstOption
              value={state.selectedHotelLabel}
              disabled={state.applyToAllHotels || !appState?.hotelList?.length}
              bordered={false}
            >
              {state.hotels.map(hotel => (
                <Option key={hotel.id} value={hotel.name}>
                  {capitalize(hotel?.name)}
                </Option>
              ))}
            </AutoComplete>
          ) : null}
        </div>
      </div>
    </div>
  );
}
