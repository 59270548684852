import { AppContext } from 'App.context.js';
import DotIndicator from 'Components/common/DotIndicator/DotIndicator.jsx';
import Colors from 'constants/Colors.js';
import React, { useContext } from 'react';
import styles from './PoliceReportCaption.styles.js';
import { WarningOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

export default function PoliceReportCaption() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div style={styles.container}>
      <div style={styles.caption}>
        <DotIndicator color={Colors.testOk} />
        <div>{translate('policeReport.captionOk')}</div>
      </div>
      <div style={styles.caption}>
        <DotIndicator color={Colors.yellowPill} />
        <div>{translate('policeReport.captionPending')}</div>
      </div>
      <div style={styles.caption}>
        <DotIndicator color={Colors.red} />
        <div>{translate('policeReport.captionError')}</div>
      </div>
      <div style={styles.caption}>
      <Popover content={translate('policeReport.retrySendingMessage')} trigger="hover">
      <WarningOutlined
                    style={{
                      color: Colors.yellowButton,
                      marginRight: 8,
                      padding: 4,
                      border: `1px solid ${Colors.yellowButton}`,
                      borderRadius: 2,
                      fontSize: 20,
                    }}
                  />
        </Popover>
      </div>
    </div>
  );
}
