export default {
  REFERENCE_KEY: 'reference',
  CONTRACTDATE_KEY: 'contractDate',
  ENTRANCE_KEY: 'entrance',
  DEPARTURE_KEY: 'departure',
  NUMGUESTS_KEY: 'numGuests',
  PAXES_KEY: 'paxes',
  ADULTS_KEY: 'adults',
  NAME_KEY: 'name',
  SURNAME_KEY: 'surname',
  SECONDSURNAME_KEY: 'secondSurname',
  CONTACTVALUE_KEY: 'contactValue',
  PAYMENTTYPE_KEY: 'paymentType',
  ROLETYPE_KEY: 'roleType',
  DOCUMENTTYPE_KEY: 'documentType',
  DOCUMENTNUMBER_KEY: 'documentNumber',
  DOCUMENTSUPPORT_KEY: 'supportDocument',
  CONTACTTYPE_KEY: 'contactType',
  FAMILYRELATIONSHIP_KEY: 'relationship',
  COUNTRY_KEY: 'country',
  MUNICIPALITY_KEY: 'municipalityCode',
  MUNICIPALITYNAME_KEY: 'municipality',
  ADDRESS_KEY: 'address',
  ZIPCODE_KEY: 'zipCode',
  DOCUMENTSUPPORT: 'documentSupport',
  BIRTHDATE_KEY: 'birthDate',
  CHILDREN_KEY: 'childrenCount',
};
