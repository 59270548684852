export default `<p align="center"><img src="[chain_logo]" width="auto" height="100"></p>

<p style="font-family: &quot;Times New Roman&quot;, serif; margin: 0cm; text-align: center;"><span style="font-family: Calibri, sans-serif;"><span style="font-size: 21px;"><strong>入境登记表</strong></span><span style="font-size: 16px;">&nbsp;</span></span></p>
&nbsp;
<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">租赁公司信息&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 95%; border-collapse: collapse; margin-left: 2.32975%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">登记号/Cardex号:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[police_report_number]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">公司名称或持有人名称:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_name]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">CIF/VAT/NIF:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">城市:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city]&nbsp;</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">省份:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_province]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">固定电话或手机:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">电子邮箱地址:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">公司网址:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_web]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">广告链接:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_ad_url]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">住宿信息&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.8208%; border-collapse: collapse; margin-left: 2.50896%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">住宿类型:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[establishment_type]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">名称:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">详细地址:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">邮政编码:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">城市与省份:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city], [CIF_province]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">旅客信息&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.4624%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">姓名:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_first_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">姓氏:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">次姓:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_second_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">性别:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_gender]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">证件号:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">证件支持编号:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_support_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">证件类型:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_type]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">签发日期:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expedition_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">到期日期:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expiration_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">证件国家:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">国籍:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">出生日期:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_birth_date]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">常住地:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">地址:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_address]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">邮政编码:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">城市:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_city]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">省份:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_province]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">国家:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_country]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">固定电话:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_landline_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">手机号码:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">电子邮箱:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">旅客人数:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[num_guests]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">亲属关系:</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_family_relationship]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

<div style="page-break-before:always;margin-top:5cm"></div>&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">交易信息&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.1239%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt; margin-bottom: 40px;">
<tbody>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">合同信息:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">参考号:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[reference_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">日期:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">合同执行信息:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">入境日期与时间:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date],&nbsp;[entrance_time]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">离境日期与时间:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[departure_date],&nbsp;[departure_time]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">物业信息:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">详细地址:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">房间数量:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_room_count]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">网络连接:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_has_internet]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">网络连接:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">支付方式:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_payment_type]</span></p>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p>[legal_cardex]<br><br></p>

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">签名</span></strong></p>
<p><img src="[guest_signature]" width="auto" height="200"></p>
<p></p>`;