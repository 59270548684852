export default `<p align="center"><img src="[chain_logo]" width="auto" height="100"></p>

<p style="font-family: &quot;Times New Roman&quot;, serif; margin: 0cm; text-align: center;"><span style="font-family: Calibri, sans-serif;"><span style="font-size: 21px;"><strong>ФОРМА ВХОДА</strong></span><span style="font-size: 16px;">&nbsp;</span></span></p>
&nbsp;
<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">ДАННЫЕ АРЕНДОДАТЕЛЯ&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 95%; border-collapse: collapse; margin-left: 2.32975%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Номер части/Cardex номер:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[police_report_number]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Название или юридическое наименование владельца:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_name]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">CIF/VAT/NIF:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Город:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city]&nbsp;</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Область:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_province]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Телефон стационарный и/или мобильный:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Адрес электронной почты:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_business_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Сайт компании:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_web]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 43.609%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">URL для идентификации объявления:&nbsp;</span></p>
        </td>
        <td style="width: 56.203%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_ad_url]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">ДАННЫЕ УСТАНОВКИ&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.8208%; border-collapse: collapse; margin-left: 2.50896%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Тип заведения:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[establishment_type]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Название:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Полный адрес:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Почтовый индекс:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.6692%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Город и область:&nbsp;</span></p>
        </td>
        <td style="width: 57.1429%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city], [CIF_province]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">ДАННЫЕ ПУТЕШЕСТВЕННИКА&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.4624%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt;">
<tbody>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Имя:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_first_name]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Фамилия:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Стационарный телефон:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_second_surname]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Пол:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_gender]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Номер документа:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Номер документа поддержки:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_support_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Тип документа:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_document_type]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Дата выдачи:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expedition_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Срок действия:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_expiration_date]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Страна документа:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Гражданство:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Дата рождения:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_birth_date]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Место постоянного проживания:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Адрес:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_address]</span></p>
        </td>
    </tr>
        <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Почтовый индекс:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_postal_code]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Город:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_city]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Область:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_province]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Страна:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_country]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Стационарный телефон:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_landline_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Мобильный телефон:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_phone]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Электронная почта:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_email]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Количество путешественников:&nbsp;</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[num_guests]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.1053%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Степень родства:</span></p>
        </td>
        <td style="width: 57.7068%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_family_relationship]</span></p><br>
        </td>
    </tr>
</tbody>
</table>

<div style="page-break-before:always;margin-top:5cm"></div>&nbsp;

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:10px">ДАННЫЕ ТРАНЗАКЦИИ&nbsp;</span></strong></p>

<table style="float: left; border: none; width: 94.1239%; border-collapse: collapse; margin-left: 2.86738%; margin-right: -2.25pt; margin-bottom: 40px;">
<tbody>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Данные контракта:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Номер ссылки:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[reference_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Дата:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Данные выполнения контракта:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Дата и время входа:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[entrance_date],&nbsp;[entrance_time]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Дата и время выхода:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[departure_date],&nbsp;[departure_time]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Данные объекта:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Полный адрес:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[address]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Количество номеров:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_room_count]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Подключение к интернету:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_has_internet]</span></p><br>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Данные о платеже:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><br></p>
        </td>
    </tr>
    <tr>
        <td style="width: 42.2932%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;margin-left:15px;">Тип:&nbsp;</span></p>
        </td>
        <td style="width: 57.5188%; padding: 0cm; height: 14.65pt; vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[guest_payment_type]</span></p>
        </td>
    </tr>
</tbody>
</table>

&nbsp;

<p>[legal_cardex]<br><br></p>

<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Подпись</span></strong></p>
<p><img src="[guest_signature]" width="auto" height="200"></p>
<p></p>`;