import React, { useContext } from 'react';
import styles from './PoliceSES.styles.js';
import MailTemplateInput from 'Components/Dashboard/Guestlink/MailTemplateDetail/MailTemplateInput/MailTemplateInput.jsx';
import { AppContext } from 'App.context.js';
import Loading from 'Components/common/Loader/Loading.jsx';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import { PoliceReportConfigContext } from '../PoliceReportConfig.context.js';

export default function PoliceSES() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useContext(PoliceReportConfigContext);

  return (
    <div>
      <div style={styles.credentialsContainer}>
        <MailTemplateInput
          value={state.sesUser}
          name={translate('policeReport.sesUser')}
          onChange={value => actions.setField('sesUser', value.trim())}
          disabled={state.isLoading}
        />
        <MailTemplateInput
          value={state.sesPass}
          name={translate('policeReport.sesPass')}
          onChange={value => actions.setField('sesPass', value.trim())}
          disabled={state.isLoading}
          password
        />
      </div>
      <div style={styles.credentialsContainer}>
        <MailTemplateInput
          value={state.lessorCode}
          name={translate('policeReport.lessorCode')}
          onChange={value => actions.setField('lessorCode', value.trim())}
          disabled={state.isLoading}
        />
        <MailTemplateInput
          value={state.establishmentCode}
          name={translate('policeReport.establishmentCode')}
          onChange={value => actions.setField('establishmentCode', value.trim())}
          disabled={state.isLoading}
        />
      </div>
      <div style={styles.buttonContainer}>
        {state.isLoading && <Loading size={'small'} />}
        <AppButton
            text={translate('policeReport.checkConnection')}
            secondary
            action={() => actions.onCheckSESConnection()}
            disabled={state.isLoading}
          />
        <AppButton
          text={translate('policeReport.save')}
          action={() => actions.onSaveSESConfig()}
          disabled={state.isLoading}
        />
      </div>
    </div>
  );
}
