const letters = [
  'T',
  'R',
  'W',
  'A',
  'G',
  'M',
  'Y',
  'F',
  'P',
  'D',
  'X',
  'B',
  'N',
  'J',
  'Z',
  'S',
  'Q',
  'V',
  'H',
  'L',
  'C',
  'K',
  'E',
];

export default function validateDocumentNumber(docType, documentNumber, country, residencePermitSuppDoc) {
  if (!documentNumber) return false;
  documentNumber = documentNumber.toUpperCase();
  let isValid = false;

  switch (docType) {
    case 'dni':
      {
        isValid = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/.test(documentNumber);
        if (!isValid) break;

        const number = documentNumber.substr(0, 8);
        const control_digit = documentNumber.substr(8, 1);
        isValid = letters[number % 23] == control_digit;
      }
      break;
    case 'residencePermit':
      {
        isValid = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/.test(documentNumber);
        if (!isValid) break;

        const letter = documentNumber.substr(0, 1);
        let firsLetter = letter;
        if (letter == 'X') firsLetter = '0';
        if (letter == 'Y') firsLetter = '1';
        if (letter == 'Z') firsLetter = '2';
        const number = `${firsLetter}${documentNumber.substr(1, 7)}`;
        const control_digit = documentNumber.substr(8, 1);
        isValid = letters[number % 23] == control_digit;
      }
      break;
    case 'passport': {
      if (typeof documentNumber !== 'string') {
        return false;
      } else {
        return true;
      }

      // switch (country) {
      //   case 'ESP':
      //     return /^[A-Za-z]{2,3}[0-9]{6}$/.test(documentNumber);
      //   case 'GBR':
      //     return /^[0-9]{9}$/.test(documentNumber);  
      //   case 'USA':
      //     return /^[0-9]{9}$/.test(documentNumber) || /^[A-Za-z]{1}[0-9]{8}$/.test(documentNumber);
      //   case 'DNK':
      //     return /^[0-9]{9}$/.test(documentNumber);
      //   case 'NOR':
      //     return /^[0-9]{8}$/.test(documentNumber) || /^[A-Za-z]{3}[0-9]{6}$/.test(documentNumber);
      //   case 'SEW':
      //     return /^[0-9]{8}$/.test(documentNumber) || /^[A-Za-z]{2}[0-9]{7}$/.test(documentNumber);
      //   case 'FIN':
      //     return /^[0-9A-Za-z]{8,9}$/.test(documentNumber);
      //   case 'DEU':
      //     return /^[0-9A-Za-z]{9}$/.test(documentNumber) || /^[A-Za-z]{1}[0-9]{7}$/.test(documentNumber);
      //   case 'NLD':
      //     return /^[0-9A-Za-z]{9}$/.test(documentNumber);
      //   case 'BEL':
      //     return /^[0-9A-Za-z]{8,9}$/.test(documentNumber);
      //   case 'FRA':
      //     return /^[0-9A-Za-z]{9}$/.test(documentNumber);
      //   case 'ARG':
      //     return /^[0-9A-Za-z]{9}$/.test(documentNumber);
      //   case 'CHL':
      //     return /^[0-9A-Za-z]{7,9}$/.test(documentNumber);
      //   case 'ITA':
      //     return /^[A-Za-z]{2}[0-9]{7}$/.test(documentNumber);
      //   case 'CHE':
      //     return /^[A-Za-z]{1}[0-9]{7}$/.test(documentNumber) || /^[0-9A-Za-z]{8}$/.test(documentNumber);
      //   case 'GRC':
      //     return /^[A-Za-z]{2}[0-9]{7}$/.test(documentNumber);
      //   case 'GTM':
      //     return /^[0-9]{9}$/.test(documentNumber);
      //   default:
      //     return true;
      // }
    }
    case "supportDocument":  {

      if (residencePermitSuppDoc) {
        const resicendePermitRegex = /^[A-Z]\d{7}[A-Z]$/;
        return resicendePermitRegex.test(documentNumber);
      }

      const regex = /^[A-Za-z]{3}[0-9]{6}$/;
      return regex.test(documentNumber);
  }
    default:
      isValid = true;
      break;
  }

  return isValid;
}
