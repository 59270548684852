import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { AppContext } from 'App.context';
import ContentBox from 'Components/common/ContentBox/ContentBox';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout';
import paymentMethodConfiguredRequest from 'utils/networking/api/auth/paymentMethodConfigured.service';
import AccountOwnerDetail from './AccountOwnerDetail/AccountOwnerDetail';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import HotelSelector from 'Components/common/HotelSelector/HotelSelector';
import AccountFunctionalities from './AccountFunctionalities/AccountFunctionalities';

export default function AccountDetail() {
  const {
    appState,
    appActions: { translate, getPaymentMethods },
  } = useContext(AppContext);
  const history = useHistory();

  const isOwnerUser = appState.userId === appState.ownerId;

  const params = new URLSearchParams(window.location.search);
  const [selectedHotel, onHotelSelected] = useState();

  useEffect(() => {
    if (params.get('registerPaymentMethod')) {
      const registerPaymentMethod = params.get('registerPaymentMethod');
      if (registerPaymentMethod === 'success') {
        paymentMethodConfigured(params.get('email'));
      } else {
        notification.warn({
          message: translate('register.registerKO'),
        });
      }
      params.delete('registerPaymentMethod');
      params.delete('email');
      history.replace({
        registerPaymentMethod: params.toString(),
        email: params.toString(),
      });
    } else {
      getPaymentMethods(appState.ownerId);
    }
  }, []);

  function paymentMethodConfigured(email) {
    paymentMethodConfiguredRequest({
      email,
      lang: appState.currentLanguage?.id,
    })
      .then(_data => {
        notification.success({
          message: translate('paymentMethods.addedSuccess'),
        });
        setTimeout(() => {
          getPaymentMethods(appState.ownerId);
        }, 1000);
      })
      .catch(error => {
        notification.error({
          message: error?.response?.data?.message,
        });
      });
  }

  return (
    <DefaultLayout showHotelSelector={false}>
      <ContentBox title={translate('user.accountDetailTitle')}>
        <AccountOwnerDetail />
      </ContentBox>
      {isOwnerUser && (
        <ContentBox title={translate('user.changePasswordTitle')}>
          <ChangePasswordForm />
        </ContentBox>
      )}
      <ContentBox title={translate('layout.paymentMethods')}>
        <PaymentMethods paymentMethod={appState.paymentMethods} userId={appState.ownerId} />
      </ContentBox>
      <ContentBox title={translate('accountDetail.billing')} noPadding>
        <HotelSelector
          enableAllHotels={true}
          darkTheme
          onHotelSelected={onHotelSelected}
          autoSelect={true}
        />
        <div style={{ height: 16 }} />
        <AccountFunctionalities selectedHotel={selectedHotel} />
      </ContentBox>
    </DefaultLayout>
  );
}
