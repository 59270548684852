import React, { useContext, useState } from 'react';
import styles from './PoliceSFTP.styles.js';
import MailTemplateInput from 'Components/Dashboard/Guestlink/MailTemplateDetail/MailTemplateInput/MailTemplateInput.jsx';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import { AppContext } from 'App.context.js';
import Loading from 'Components/common/Loader/Loading.jsx';
import { PoliceReportConfigContext } from '../PoliceReportConfig.context.js';
import { Input, Divider } from 'antd';

const InputGroup = Input.Group;

export default function PoliceSFTP() {
  const { state, actions } = useContext(PoliceReportConfigContext);
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div>
      <div style={styles.credentialsContainer}>
        <MailTemplateInput
          value={state.ftpHost}
          name={translate('policeReport.ftpHost')}
          onChange={value => actions.setField('ftpHost', value)}
          disabled={state.isLoading}
        />
        <div style={styles.port}>
          <MailTemplateInput
            value={state.ftpPort}
            name={translate('policeReport.ftpPort')}
            onChange={value => actions.setField('ftpPort', value)}
            disabled={state.isLoading}
          />
        </div>
      </div>
      <div style={styles.credentialsContainer}>
        <MailTemplateInput
          value={state.ftpUser}
          name={translate('policeReport.ftpUser')}
          onChange={value => actions.setField('ftpUser', value)}
          disabled={state.isLoading}
        />
        <MailTemplateInput
          value={state.ftpPass}
          name={translate('policeReport.ftpPass')}
          onChange={value => actions.setField('ftpPass', value)}
          disabled={state.isLoading}
          password
        />
      </div>
      <div style={styles.credentialsContainer}>
        <MailTemplateInput
          value={state.filePrefix}
          name={translate('policeReport.ftpFilePrefix')}
          onChange={value => actions.setField('filePrefix', value)}
          disabled={state.isLoading}
        />

        <MailTemplateInput
          value={'YYYY-MM-DD'}
          name={translate('policeReport.fileFormat')}
          onChange={value => actions.setField('fileFormat', value)}
          disabled={true}
        />

        <MailTemplateInput
          value={state.fileSufix}
          name={translate('policeReport.ftpFileSufix')}
          onChange={value => actions.setField('fileSufix', value)}
          disabled={state.isLoading}
        />

        <MailTemplateInput
          value={state.extension}
          name={translate('policeReport.extension')}
          onChange={value => actions.setField('extension', value)}
          disabled={state.isLoading}
        />
      </div>
      <div style={styles.credentialsContainer}>
        <MailTemplateInput
          value={state.filePrefix + state.fileFormat + state.fileSufix + state.extension}
          name={translate('policeReport.completeFileName')}
          disabled={true}
        />
      </div>
      <MailTemplateInput
        value={state.ftpDirPath}
        name={translate('policeReport.ftpDirPath')}
        onChange={value => actions.setField('ftpDirPath', value)}
        disabled={state.isLoading}
      />

      <div style={styles.credentialsContainer}>
        <MailTemplateInput
          value={state.testFile}
          name={translate('policeReport.testFile')}
          onChange={value => actions.setField('testFile', value)}
          disabled={state.isLoading}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '22px',
            marginBottom: '16px',
          }}
        >
          <AppButton
            text={translate('policeReport.validateFileExport')}
            secondary
            action={() => actions.validateFileExport()}
            disabled={state.isLoading}
          />
        </div>
      </div>

      <div style={styles.buttonContainer}>
        {state.isLoading && <Loading size={'small'} />}
        <AppButton
          text={translate('policeReport.checkConnection')}
          secondary
          action={() => actions.onCheckConnection()}
          disabled={state.isLoading}
        />

        <AppButton
          text={translate('policeReport.save')}
          action={() => actions.onSaveFTPConfig()}
          disabled={state.isLoading}
        />
      </div>
      {state.ftpErrors.length === 0 && state.isValidation && (
        <div>
          <Divider style={{ borderColor: '#009ae9' }}>
            {translate('policeReport.ftpResult')}
          </Divider>
          <div style={styles.validateSFTPWithoutErrors}>
            {translate('policeReport.validateSFTPWithoutErrors')}
          </div>
        </div>
      )}
      {state.ftpErrors.length > 0 && state.isValidation && (
        <div>
          <Divider style={{ borderColor: '#009ae9' }}>{translate('policeReport.ftpFiles')}</Divider>
          <div style={styles.credentialsContainer}>
            <ul>
              {state.ftpErrors.map((file, index) => (
                <li
                  key={index}
                  style={file.error === 'error' ? { color: 'red', fontWeight: 'bold' } : {}}
                >
                  {file.message}
                </li>
              ))}
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: translate('policeReport.explainRedColorFileValidation').replace(
                    /\{(.*?)\}/g,
                    '<span style="color: red; font-weight: bold;">$1</span>'
                  ),
                }}
              />
            </ul>
          </div>
        </div>
      )}
      {state.ftpFiles.length > 0 && state.isFileList && (
        <div>
          <Divider style={{ borderColor: '#009ae9' }}>{translate('policeReport.ftpFiles')}</Divider>
          <div style={styles.credentialsContainer}>
            <ul>
              {state.ftpFiles.map((file, index) => (
                <li
                  key={index}>
                  {file}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
