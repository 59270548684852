import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { AppContext } from 'App.context';
import AppModal from 'Components/common/AppModal/AppModal';
import BaseTable from 'Components/common/BaseTable/BaseTable';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import useAccountFunctionalitiesHook from './AccountFunctionalities.hook';

export default function AccountFunctionalities({ selectedHotel }) {
  const {
    appActions: { translate },
    appState
  } = useContext(AppContext);

  const { state, actions } = useAccountFunctionalitiesHook(selectedHotel);

  const columns = [
    {
      title: translate('accountDetail.functionality'),
      key: 'key',
      dataIndex: 'key',
      align: 'left',
      render: (_, row) => (
        <div style={{ display: 'flex', textAlign: 'left' }}>
          {translate(`accountDetail.o_billing.${row.key === 'Booking.com' ? 'booking' : row.key}`)}
        </div>
      ),
    },
    {
      title: translate('accountDetail.available'),
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      render: (_, row) => {
        const isSelectedHotelNull = selectedHotel?.key === null;
        const isCheckin = row.key === 'checkin';
  
        // When "All Hotels" is selected, only show the button for the "Check-in" functionality
        if (isSelectedHotelNull && isCheckin) {
          return !row.requested && !row.active ? (
            <AppButton
              text={translate('accountDetail.contract')}
              action={() => actions.onContractClicked(row.method, row.key)}
            />
          ) : null;
        }
  
        // When a hotel is selected, show all buttons except for the "Check-in" functionality
        if (!isSelectedHotelNull && !isCheckin) {
          return !row.requested && !row.active ? (
            <AppButton
              text={translate('accountDetail.contract')}
              action={() => actions.onContractClicked(row.method, row.key)}
            />
          ) : null;
        }
  
        return null;
      },
    },
    {
      title: translate('accountDetail.requested'),
      key: 'requested',
      dataIndex: 'requested',
      align: 'center',
      render: (_, row) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {!row.active ? (
            row.requested ? (
              <CheckOutlined style={{ color: Colors.greenPill, fontSize: 16 }} />
            ) : (
              <MinusOutlined style={{ fontSize: 16 }} />
            )
          ) : null}
        </div>
      ),
    },
    {
      title: translate('accountDetail.active'),
      key: 'active',
      dataIndex: 'active',
      align: 'center',
      render: (_, row) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {row.active ? (
            <CheckOutlined style={{ color: Colors.greenPill, fontSize: 16 }} />
          ) : !row.requested ? (
            <MinusOutlined style={{ fontSize: 16 }} />
          ) : null}
        </div>
      ),
    },
  ];

  if (appState.superUser) {
    columns.push({
      title: translate('accountDetail.activateDeactivate'),
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      render: (_, row) =>
        <AppButton
          text={translate(row.active ? 'accountDetail.deactivate' : 'accountDetail.activate')}
          action={() => actions.onHandleSuscriptionClicked(row.key, row.active)}
          disabled={row.isActivatable ? false : true}
        />
    });
  }

  return state.data && state.data.length ? (
    <div>
      <Table
        loading={state.isLoading}
        columns={columns}
        dataSource={state.data}
        pagination={false}
      />
      {state.showEmailWarning && (
        <AppModal
          show={state.showEmailWarning}
          title={translate('accountDetail.emailWarningTitle')}
          description={translate('accountDetail.emailWarning')}
          okText={translate('Ok')}
          okAction={actions.requestFunctionality}
          cancelAction={() => actions.showSendEmailWarning(false)}
        />
      )}

      {state.showDashboardWarning && (
        <AppModal
          show={state.showDashboardWarning}
          title={translate('accountDetail.emailWarningTitle')}
          description={translate('accountDetail.dashboardWarning')}
          okText={translate('Ok')}
          okAction={() => actions.requestFunctionality(true)}
          cancelAction={() => actions.showDashboardWarning(false)}
        />
      )}
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 32 }}>
      <Loading />
    </div>
  );
}
