export default {
  menu: {
    items: 'Items',
    status: 'Estados de cross-selling',
    report: 'Reportes',
  },
  type: {
    service: 'Servicio',
    good: 'Producto',
    transfer: 'Transfer',
  },
  items: 'Items',
  pax: 'Pax',
  to: 'a',
  itemId: 'Item Id',
  status: 'Estado',
  picture: 'Imagen',
  itemType: 'Tipo de item',
  itemTitle: 'Título del item',
  hotelName: 'Nombre del hotel',
  createItem: 'Crear item',
  confirmationType: 'Tipo de confirmación',
  automatic: 'Automático',
  manual: 'Manual',
  description: 'Descripción',
  photoGallery: 'Galería de fotos',
  dragAndDrop: 'Arrastra tus archivos aquí o haz clic para seleccionarlos',
  maxFileSize: 'Tamaño máximo de archivo: 2.5MB',
  maxFileNumber: 'El máximo permitido es de 5 imágenes.',
  uploadsuccess: 'Subido con éxito',
  uploaderror: 'Error al subir',
  dealId: 'ID',
  locator: 'Localizador',
  guestName: 'Nombre del huésped',
  itemName: 'Nombre del item',
  slot: 'Slot',
  date: 'Fecha',
  typesOfRoomsCreated: 'Tipos de habitaciones creadas',
  typeOfPrice: 'Tipo de precio',
  price: 'Precio',
  totalPrice: 'Precio total',
  deleteItem: {
    title: '¿Estás seguro de querer eliminar este item?',
    content: 'Se eliminará el item seleccionado',
    okText: 'Eliminar item',
  },
  createItemDescription: 'Crea todos los productos y servicios disponibles en tu establecimiento para mejorar las oportunidades de cross-sell para tus huéspedes',
  groupBy: 'Agrupado por',
  viewDetails: 'Ver detalles',
  itemDetail: {
    back: 'Volver',
    saveItem: 'Guardar item',
    itemInformation: 'Información del item',
    itemInformationDescription: 'Introduce la información principal del item que se mostrará en las tarjetas.',
    title: 'Título',
    titleHint: 'Introduce el título del item',
    availableQuantity: 'Límite por transacción',
    itemDescription: 'Descripción del item',
    termsAndPolicies: 'Términos y condiciones',
    termsAndPoliciesPlaceholder: 'Introduce los términos y condiciones del item',
    priceAndPaymentSettings: 'Precio',
    servicePrice: 'Precio por persona',
    goodPrice: 'Precio',
    transferPrice: 'Precio por persona y trayecto',
    priceExplanation: 'Selecciona el tipo de precio y define cuánto pagarán tus huéspedes por la oferta.',
    serviceAvailability: 'Disponibilidad del servicio',
    transferAvailability: 'Disponibilidad del transfer',
    listSlotTime: 'Lista de slots',
    saveSuccess: '¡Item creado con éxito!',
    updateSuccess: '¡Item actualizado con éxito!',
    saveError: 'Error al guardar el item.',
    slot: {
      title: 'Título',
      untitled: "Sin título",
      titleHint: 'Añade un nombre a este conjunto de días de disponibilidad y horario',
      quantity: 'Total de personas disponibles por slot',
      daysAvailability: 'Disponibilidad de días',
      createSlot: 'Crear slot',
      updateSlot: 'Actualizar slot',
      tripType: {
        name: 'Tipo de trayecto',
        oneWay: 'Solo ida',
        return: 'Solo vuelta',
        roundTrip: 'Ida y vuelta',
      },
      days: {
        sunday: 'Domingo',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
      },
      daysShort: {
        sunday: 'Dom',
        monday: 'Lun',
        tuesday: 'Mar',
        wednesday: 'Mié',
        thursday: 'Jue',
        friday: 'Vie',
        saturday: 'Sáb',
      },
    },
  },
  itemTypes: {
    service: 'Servicio',
    good: 'Producto',
    transfer: 'Transfer',
  },
  active: 'Activo',
  removed: 'Eliminado',
  disabled: 'Desactivado',
  maxFileUploadReached: 'Puedes subir un máximo de 5 imágenes',
  totalCrossSell: 'Total Cross-sellings',
  crossSellRevenue: 'Ingresos de Cross-selling',
  crossSellList: 'Lista de Cross-sellings',
  crossSell: 'cross-sell',
  last7Days: 'Últimos 7 días',
  lastMonth: 'Último mes',
  last30Days: 'Últimos 30 días',
  days: 'Días',
  month: 'Mes',
  accepted: 'Aceptado',
  rejected: 'Rechazado',
  approved: 'Aprobado',
  pending: 'Enviado y pendiente del SES',
  activate: 'Activar',
  delete: 'Eliminar',
  seeDetails: 'Ver detalles',
  changeToRejected: 'Cambiar a rechazado',
  changeToApproved: 'Cambiar a aprobado',
  disable: 'Desactivar',
  paymentStatus: 'Estado del pago',
  transactionCode: 'Transacción',
  succeed: 'Aprobado',
  upsertValidations: {
    name: 'El nombre de la item es requerido',
    description: 'La descripción de la item es requerida',
    missingPmsId: 'El PMS ID es requerido',
    missingTranslation: 'Falta traducción en otro idioma',
    createItem: 'Item creada con éxito',
    modifiedItem: 'Item modificada con éxito.',
  },
  reports: {
    itemNumbers: 'Unidades vendidas',
    revenue: 'Ingresos Generados',
    transactionCount: 'Número de transacciones',
  },
  validation: {
    quantityRequired: 'Se requiere cantidad y debe ser mayor que 0.',
    slotQuantityRequired: 'Cada slot debe tener una cantidad mayor que 0.',
    priceRequired: 'Se requiere indicar el precio',
  },
};