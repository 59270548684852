import { useContext, useState } from 'react';
import { notification } from 'antd';
import { AppContext } from '../../../App.context';
import { ApiContext } from '../../../utils/networking/Api.context';

const useRequestSuscriptionHook = enableAllHotels => {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    isLoading: false,
    sendSuscriptionRequestEnabled: true,
  });

  const actions = {
    requestModuleFunctionality: ({ suscription }) => {
      updateState({ isLoading: true });
      let functionality = null;
      switch (suscription) {
        case 'payment':
          functionality = 'Payment Module';
          break;
        case 'door_opening':
          functionality = 'Door Opening Module';
          break;
        case 'guestlink':
          functionality = 'Guestlink Module';
          break;
        case 'checkout':
          functionality = 'Checkout Module';
          break;
        case 'checkin':
          functionality = 'Checkin Module';
          break;
        default:
          break;
      }

      API.requestFunctionalityContract({
        hotelId: getSelectedHotel().key,
        suscription,
        functionality,
      })
        .then(_ => {
          actions.sendRequestPoliceFunctionalityEmail({ functionality });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    sendRequestPoliceFunctionalityEmail: ({ functionality }) => {
      API.sendRequestFuncionalityEmail({
        hotelId: getSelectedHotel().key,
        functionality,
      })
        .then(_ => {
          notification.success({
            message: translate('requestSuscription.missingSuscription.requestSent'),
          });
          updateState({ isLoading: false, sendSuscriptionRequestEnabled: false });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    getTitleModule: suscription => {
      let title = 'Unknown module';
      switch (suscription) {
        case 'payment':
          title = translate('payment.missingSuscription.title');
          break;
        case 'door_opening':
          title = translate('doorOpening.missingSuscription.title');
          break;
        case 'guestlink':
          title = translate('guestlink.missingSuscription.title');
          break;
        case 'checkout':
          title = translate('checkOutModule.missingSubscription.title');
          break;
        case 'checkin':
          title = translate('checkInList.missingSubscription.title');
          break;
        default:
          break;
      }
      return title;
    },

    getDescriptionModule: suscription => {
      let description = '';
      switch (suscription) {
        case 'payment':
          description = translate('payment.missingSuscription.description');
          break;
        case 'door_opening':
          description = translate('doorOpening.missingSuscription.description');
          break;
        case 'guestlink':
          description = translate('guestlink.missingSuscription.description');
          break;
        case 'checkout':
          description = translate('checkOutModule.missingSubscription.description');
          break;
        case 'checkin':
          description = translate('checkInList.missingSubscription.description');
          break;
        default:
          break;
      }
      return description;
    }
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default useRequestSuscriptionHook;
