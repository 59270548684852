export default {
  configProperties: {
    allowedSince: 'allowed_since',
    allowedUntil: 'allowed_until',
    requiredDocuments: 'required_documents',
    requiredSignatures: 'required_signatures',
    requiredCardexSignatures: 'required_cardex_signatures',
    captureDocs: 'capture_docs',
    entranceDate: 'entranceDate',
    bookingCode: 'bookingCode',
    bookingHolder: 'bookingHolder',
    nights: 'nights',
    paperlessTimeout: 'paperless_start_checkin_timeout_seconds',
    paperlessFinalizeTimeout: 'paperless_finalize_checkin_timeout_seconds',
    saveCardex: 'save_cardex',
    sendCardex: 'send_cardex',
    saveContract: 'save_contract',
    sendContract: 'send_contract',
    paperlessVersion: 'paperless_version',
    paperlessImagesSliderTimeout: 'paperless_images_slider_timeout',
    paperlessShowSignatureDocuments: 'paperless_show_signature_documents',
    paperlessAutoNextCheckin: 'paperless_auto_next_checkin',
  },
  selectorOptions: {
    none: '0',
    one: '1',
    oneInLastProcess: '1_in_last_process',
    holder: 'holder',
    numAdults: 'num_adults',
    numGuests: 'num_guests',
    guestOver2: 'guest_over_2',
    guestOver10: 'older_than_10',
    guestOver12: 'guest_over_12',
    guestOver14: 'guest_over_14',
    guestOver16: 'guest_over_16',
    optional: 'optional',
    required: 'required',
    paymentWarranty: 'payment+warranty',
    payment: 'payment',
    warranty: 'warranty',
    oneTaxes: '1+taxes',
    optionalTaxes: 'optional+taxes',
    active: 'active',
    disabled: 'disabled',
    capture_docs_optional: 'optional',
    capture_docs_disabled: '0',
    capture_docs_mandatory: '1',
  },
  guestLinkSelectorOptions: {
    yes: true,
    no: false,
    none: 'none',
    holder: 'holder',
    numAdults: 'adults',
    numGuests: 'all',
  },
  paperlessVersionOptions: {
    v1: 'v1',
    v2: 'v2',
  }
};
